/**
 * Simple error alert
 */

import React from 'react';

import '../styles/ErrorAlert.css';

interface Props {
    message: string;
}

const ErrorAlert: React.FC<Props> = ({ message }) => {
    return (
        <div className="error-alert">
            <p>{message}</p>
        </div>
    );
}

export default ErrorAlert;