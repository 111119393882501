import React from 'react';

import '../styles/LoadingCircle.css';

const LoadingCircle: React.FC = () => {
    return (
        <div className="progress-bar">
            <div className="circle border"></div>
        </div>
    );
}

export default LoadingCircle;