import React from 'react';

import '../styles/GradientButton.css';
import { gradientStyleForColors } from '../util/helpers';

interface Props {
    color1: string;
    color2: string;
    onClick: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
}

const GradientButton: React.FC<Props> = ({ color1, color2, onClick, disabled = false, children }) => {
    const gradientStyle = gradientStyleForColors(color1, color2);

    return (
        <button className="button" disabled={disabled} type="button" onClick={onClick} style={gradientStyle}>
            {children}
        </button>
    );
}

export default GradientButton;