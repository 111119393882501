import React from 'react';

import '../styles/Frontpage.css';
import background from '../assets/background.png'
import { track } from '../util/MixPanel';

interface Props {
    order: () => void;
}

const Frontpage: React.FC<Props> = ({order}) => {
    track("Frontpage loaded", {})
    return (
        <>
            <div className="background" style={{ backgroundImage:`url(${background})`}}></div>
            <div className="container">
                <div className="backgroundOverlay"></div>
                <div className="frontpage">
                    <div className="header">
                        <h1>OneTime</h1>
                        <p className="tagline">Fang øyeblikkene, ett bilde om gangen</p>
                        <p className='description'>OneTime tar konseptet med engangskamera til en ny, digital tidsalder, og gir alle i din vennegruppe muligheten til å være fotografene.</p>
                        <p className='description'>Gjennom en delt digital plattform kan hver person fange, dele og nyte øyeblikkene i sanntid, og bidra til et levende, felles fotoalbum som vokser gjennom arrangementet.</p>
                        <p className='description'>Det spiller ingen rolle om dere er to eller to hundre - OneTime er like enkel og inkluderende for alle. Glem bekymringene med å huske kamera, laste opp og dele bilder etter festen. Med OneTime er bildene der med en gang, klar til å gjenoppleves sammen med vennene dine.</p>
                        <p className='description'>Ditt arrangement, ditt engangskamera, dine minner - delt og elsket med OneTime.</p>
                        <br /> 
                    </div>
                    <div className="actions">
                        <button className="button orderButton" type='button' onClick={order}>Lag ditt engangskamera</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Frontpage;