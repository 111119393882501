import './App.css';
import Frontpage from './views/Frontpage';
import PreCheckout from './views/PreCheckout';
import React, { useEffect, useState } from 'react';
import Success from './views/Success';
import Failure from './views/Failure';
import { track } from './util/MixPanel';

enum Page {
  Frontpage,
  Checkout,
  Success,
  Failure,
}

const App: React.FC = () => {

  const [selectedPage, setSelectedPage] = useState<Page>(Page.Frontpage);

  useEffect(() => {

    //If query param "success" is set, go to success page
    if (window.location.search.includes("success=true")) {
      setSelectedPage(Page.Success);
    } else if (window.location.search.includes("canceled=true")) {
      setSelectedPage(Page.Failure);
    }
  }, []);


  const backToFrontPage = () => {
    setSelectedPage(Page.Frontpage);
  }
  const goToOrderPage = () => {
    track("Checkout button clicked", {});
    setSelectedPage(Page.Checkout);
  }

  const renderPage = () => {
    switch (selectedPage) {
      case Page.Frontpage:
        return <Frontpage order={goToOrderPage} />;
      case Page.Checkout:
        return <PreCheckout goBack={backToFrontPage} />;
      case Page.Success:
        return <Success goBack={backToFrontPage} />;
      case Page.Failure:
        return <Failure goBack={backToFrontPage} />;
    }
  }

  return (
    <>
      {renderPage()}
    </>
  );
}

export default App;
