//Overlay for Terms and Conditions

import React from 'react';
import '../styles/TermsConditionsOverlay.css';

interface Props {
    onClose: () => void;
}

const TermsConditionsOverlay: React.FC<Props> = ({ onClose }) => {

    const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="overlay" onClick={handleOutsideClick}>
            <div className="overlayContent">
                <div className="overlayHeader">
                    <h1>Vilkår og betingelser for bruk av OneTime</h1>
                    <button className="closeButton" onClick={onClose}>X</button>
                </div>
                <div className="overlayBody">
                    <p>Ved å bruke tjenesten OneTime, godtar du følgende vilkår og betingelser som er satt av ConsultWare AS (org. nr. 917540896 MVA). Vennligst les nøye gjennom vilkårene før du bruker tjenesten. Hvis du ikke samtykker i vilkårene, må du avstå fra å bruke tjenesten.</p>

                    <ol>
                        <li>
                            <h2>Begrenset rettighet til å publisere bilder:</h2>
                            <p>Ved å bruke tjenesten OneTime gir du ConsultWare AS en begrenset rettighet til å publisere bildene du tar med kameraet på deres egen kameraside. Denne rettigheten gjelder kun for presentasjon og deling av bildene og er ikke ment for markedsføring eller kommersiell bruk.</p>
                        </li>

                        <li>
                            <h2>Ansvarsfraskrivelse:</h2>
                            <p>ConsultWare AS fraskriver seg ethvert ansvar knyttet til bruk av tjenesten OneTime. Vi kan ikke holdes ansvarlige for eventuelle tap, skader eller konsekvenser som oppstår som følge av bruken av tjenesten. Vi garanterer ikke for nøyaktighet, pålitelighet eller tilgjengelighet av tjenesten eller bildene som tas med kameraet.</p>
                        </li>

                        <li>
                            <h2>Stenging av kamera ved brudd på vilkår:</h2>
                            <p>ConsultWare AS forbeholder seg retten til å stenge ned kameraet og avslutte tilgangen til tjenesten uten varsel dersom det blir oppdaget brudd på vilkårene. Ved slike brudd vil det ikke bli gitt noen form for refusjon eller erstatning.</p>
                        </li>

                        <li>
                            <h2>Bruk av kamera til lovlig formål:</h2>
                            <p>Det er ikke tillatt å bruke kameraet til noe ulovlig formål. Brukeren er selv ansvarlig for å overholde gjeldende lover og regler knyttet til fotografering og bruk av bilder.</p>
                        </li>

                        <li>
                            <h2>Begrenset erstatningsansvar:</h2>
                            <p>ConsultWare AS kan kun holdes ansvarlige for beløpet betalt for å få tilgang til kameraet. Vi påtar oss ikke ansvar for indirekte, tilfeldige, spesielle eller følgeskader, inkludert tap av fortjeneste eller tap av data.</p>
                        </li>

                        <li>
                            <h2>Personopplysninger:</h2>
                            <p>Vi samler kun inn e-postadresse og betalingsinformasjon for å kontakte deg angående kameraet og for å behandle betalingen. Vi behandler disse opplysningene i samsvar med gjeldende personvernlover.</p>
                        </li>

                        <li>
                            <h2>Endringer i vilkår og betingelser:</h2>
                            <p>ConsultWare AS forbeholder seg retten til å endre disse vilkårene og betingelsene når som helst uten forhåndsvarsel. Eventuelle endringer vil tre i kraft umiddelbart etter at de er publisert på tjenesten.</p>
                        </li>
                    </ol>

                    <p>Ved å bruke tjenesten OneTime bekrefter du at du har lest, forstått og godtar å være bundet av disse vilkårene og betingelsene. Hvis du ikke godtar vilkårene, må du avstå fra å bruke tjenesten.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsConditionsOverlay;
