import React from 'react';

import '../styles/Frontpage.css';
import '../styles/Failure.css';
import background from '../assets/background.png'
import { track } from '../util/MixPanel';

interface Props {
    goBack: () => void;
}

const FailurePage: React.FC<Props> = ({goBack}) => {
    track("Failure page reached", {});
    return (
        <>
            <div className="background" style={{ backgroundImage:`url(${background})`}}></div>
            <div className="container">
                <div className="backgroundOverlay"></div>
                <div className="frontpage">
                    <div className="header">
                        <h1>OneTime</h1>
                        <p className="tagline">Fang øyeblikkene, ett bilde om gangen</p>
                        <div className='failureBox'>
                            <h2 className='description'>Vi kunne ikke ta imot din betaling!</h2>
                            <p className='description'>Hvis du ønsker å bestille på nytt, prøv igjen ved å gå tilbake til forsiden med knappen under.</p>
                            <p className='description'>Det kan ta litt tid før adressen du valgte blir ledig på nytt. Hvis du ønsker samme adresse, vent litt, og prøv på nytt.</p>
                            <p className='description'>Får du fortsatt ikke valgt adressen etter en stund, ta kontakt, så hjelper vi deg!</p>
                            <p className='description'>Hvis du har noen spørsmål, ikke nøl med å ta kontakt på <a className="betterA" href='mailto:support@onetime.no'>support@onetime.no</a></p>
                        </div>
                    </div>
                    <div className="actions">
                        <button className="button orderButton" type='button' onClick={goBack}>Tilbake til forsiden</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FailurePage;