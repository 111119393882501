import React from 'react';

import '../styles/StylePreview.css';
import mockup from "../assets/mockup.png"
import { gradientStyleForColors } from '../util/helpers';


interface Props {
    color1: string;
    color2: string;
}

const StylePreview: React.FC<Props> = ({ color1, color2 }) => {
    const gradientStyle = gradientStyleForColors(color1, color2);

    return (
        <div className="preview" style={gradientStyle}>
            <img width="100%" src={mockup} alt='Mockup of application with selectede colors' />
        </div>
    );
}

export default StylePreview;