import React, { useState } from 'react';
import '../styles/PreCheckoutApp.css';
import MultiSelectButton from '../components/MultiSelectButton';
import LoadingCircle from '../components/LoadingCircle';
import GradientButton from '../components/GradientButton';
import StylePreview from '../components/StylePreview';
import { getRandomColor, gradientStyleForColors } from '../util/helpers';
import ErrorAlert from '../components/ErrorAlert';
import TextButton from '../components/TextButton';
import TermsConditionsOverlay from '../components/TermsConditionsOverlay';
import DisplayOnClickTextField from '../components/DisplayOnClickTextField';
import { track } from '../util/MixPanel';

const SERVER_URL = process.env.REACT_APP_CHECKOUT_URL || '';

const OPTION_PICTURES = [
    {
        name: "15 bilder",
        value: 15,
        price: 0
    },
    {
        name: "20 bilder",
        value: 20,
        price: 5
    },
    {
        name: "30 bilder",
        value: 30,
        price: 10
    },
]

const OPTION_DURATION = [
    {
        name: "48 timer",
        value: 2,
        price: 149
    },
    {
        name: "1 uke",
        value: 7,
        price: 199
    },
    {
        name: "2 uker",
        value: 14,
        price: 249
    },
]

const exampleGradients = [
    {
        color1: "#f5e214",
        color2: "#f706bb",
    },
    {
        color1: "#3f42aa",
        color2: "#6ac9f1",
    },
    {
        color1: "#9fd7a1",
        color2: "#86ad06",
    },
    {
        color1: "#ecdcc7",
        color2: "#cec0af",
    },
    {
        color1: "#000000",
        color2: "#000000",
    },
    {
        color1: "#FF0000",
        color2: "#0000FF",
    },
];

interface FormData {
    title: string;
    color1: string;
    color2: string;
    slug: string;
    duration: number;
    numberOfPictures: number;
    promoCode: string;
    email: string;
}

interface Props {
    goBack: () => void;
}

const PreCheckoutApp: React.FC<Props> = ({goBack}) => {
    const [formData, setFormData] = useState<FormData>({
        title: '',
        color1: exampleGradients[0].color1,
        color2: exampleGradients[0].color2,
        slug: '',
        duration: OPTION_DURATION[0].value,
        numberOfPictures: OPTION_PICTURES[0].value,
        promoCode: '',
        email: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showTerms, setShowTerms] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    /**
     * This function returns a function that updates the formData property
     * with the given name.
     * It can be used in the onChange prop of an input element.
     */
    const updateFormDataProperty = (property: string) => (value: any) => {
        setFormData((prevData) => ({ ...prevData, [property]: value }));
    };

    const setDesign = (number: number) => () => {
        const gradient = exampleGradients[number];
        setFormData((prevData) => ({ ...prevData, color1: gradient.color1, color2: gradient.color2 }));
    }

    const setRandomDesign = () => {
        const color1 = getRandomColor();
        const color2 = getRandomColor();
        setFormData((prevData) => ({ ...prevData, color1: color1, color2: color2 }));
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        try {
            
            const response = await fetch(SERVER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (!response.ok) {
                const textResponse = await response.text();
                track("Order error in validation: ", {error: textResponse});
                setError(textResponse);
            }
            track("Order sent", {formData});

            const { url } = await response.json();
            setError(null);
            window.location.href = url;
        } catch (error) {
            console.error(error);
            // Handle error appropriately (e.g., show error message)
        } finally {
            setLoading(false);
        }
    };

    /**
     * Computed properties
     */
    const durationPrice = OPTION_DURATION.find((option) => option.value === formData.duration)?.price || 0;
    const numberOfPicturesPrice = OPTION_PICTURES.find((option) => option.value === formData.numberOfPictures)?.price || 0;
    const totalPrice = durationPrice + numberOfPicturesPrice;
    const slugPreview = (formData.slug !== '' ? formData.slug : '<ditt-forslag>').toLowerCase();

    // Get the gradient style for the preview, with hex 55 opacity
    const gradientStyle = gradientStyleForColors(formData.color1, formData.color2);

    return (
        <div className="pre-checkout-container">
            <div className="pre-checkout-box">
                <TextButton onClick={goBack}>{'< Tilbake'}</TextButton>
                <h1>OneTime: Digitalt Engangskamera</h1>
                <form onSubmit={handleSubmit}>
                    <div className="sections">

                    <div className="configuration-section">
                            <div className="form-group">
                                <label htmlFor="title">Title:</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                    disabled={loading}
                                    placeholder="Din Tittel"
                                />
                                <p className="explanation">Dette er tittelen som vil stå øverst på siden</p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="slug">Nettside-adresse:</label>
                                <div className="slug-input">
                                    <input
                                        type="text"
                                        id="slug"
                                        name="slug"
                                        className="align-right"
                                        value={formData.slug}
                                        onChange={handleChange}
                                        required
                                        disabled={loading}
                                        placeholder="ditt-forslag"
                                    />
                                    <span>.onetime.no</span>
                                </div>
                                <p className="explanation">Velg hva nettsiden til kameraet skal hete: <b>{slugPreview}.onetime.no</b></p>
                                <p></p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">E-post:</label>
                                <div className="email-input">
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        disabled={loading}
                                        placeholder="dittnavn@eksempel.no"
                                    />
                                </div>
                                <p className="explanation">Informasjon om ditt kamera vil bli sendt til: <b>{formData.email !== '' ? formData.email.toLowerCase() : '<din e-post>'}</b></p>
                                <p></p>
                            </div>
                            <div className='form-group'>
                                <label>Varighet:</label>
                                <MultiSelectButton<number>
                                    options={OPTION_DURATION.map((e) => e.name)}
                                    selectedValue={formData.duration}
                                    values={OPTION_DURATION.map((e) => e.value)}
                                    onChange={updateFormDataProperty("duration")}
                                    disabled={loading}
                                />
                                <p className="explanation">Ditt digitale engangskamera vil være tilgjengelig i {OPTION_DURATION.find(e => e.value === formData.duration)?.name}</p>
                            </div>
                            <div className='form-group'>
                                <label>Antall bilder:</label>
                                <MultiSelectButton<number>
                                    options={OPTION_PICTURES.map((e) => e.name)}
                                    selectedValue={formData.numberOfPictures}
                                    values={OPTION_PICTURES.map((e) => e.value)}
                                    onChange={updateFormDataProperty("numberOfPictures")}
                                    disabled={loading}
                                />
                                <p className="explanation">Hver person vil kunne ta totalt {formData.numberOfPictures} bilder</p>
                            </div>
                        </div>
                        <div className="design-section">
                            <label>Velg et design:</label>
                            <div className="form-group design-button-group">
                                {exampleGradients.map((gradient, index) => {
                                    return (
                                        <GradientButton
                                            key={index} 
                                            color1={gradient.color1}
                                            color2={gradient.color2}
                                            onClick={setDesign(index)}
                                            disabled={loading} 
                                        >
                                            {index + 1}
                                        </GradientButton>
                                    );
                                })}
                            </div>
                            <label>eller lag ditt eget:</label>
                            <div className="form-group random-design-button-group">
                                <button className="button gradientPreviewButton" disabled={loading} type="button" onClick={setRandomDesign} style={gradientStyle}>Velg tilfeldig</button>
                            </div>
                            <div className="form-group">
                                <label htmlFor="color1">Farge 1:</label>
                                <input
                                    type="color"
                                    id="color1"
                                    name="color1"
                                    value={formData.color1}
                                    onChange={handleChange}
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="color2">Farge 2:</label>
                                <input
                                    type="color"
                                    id="color2"
                                    name="color2"
                                    value={formData.color2}
                                    onChange={handleChange}
                                    required
                                    disabled={loading}
                                />
                            </div>

                        </div>

                    </div>
                    {error && <ErrorAlert message={error} />}
                    <DisplayOnClickTextField 
                        label={'Har du en rabatt-kode?'} 
                        value={formData.promoCode} 
                        onChange={updateFormDataProperty("promoCode")} 
                        disabled={loading}
                        placeholder='Skriv inn rabatt-kode her:'
                        explanation='Rabatten vil bli validert og lagt til i neste steg'
                    />
                    {showTerms && <TermsConditionsOverlay onClose={() => setShowTerms(false)} />}
                    <div className="">
                        <input
                            type="checkbox"
                            id="termsAccepted"
                            name="termsAccepted"
                            checked={termsAccepted}
                            onChange={() => setTermsAccepted(!termsAccepted)}
                            required
                            disabled={loading}
                        />
                        <label htmlFor="termsAccepted" className='linkLabel'>
                            Jeg har lest og aksepterer OneTime sine {' '}
                            <button type="button" className='linkButton' onClick={()=>setShowTerms(true)}>
                                vilkår og betingelser
                            </button>
                        </label>
                    </div>
                    <p className="price"><b>Totalt:</b> {totalPrice}kr</p>
                    <button type="submit" className="button checkoutButton" disabled={loading || !termsAccepted}>{
                        loading ? <LoadingCircle /> : 'Bestill'
                    }</button>
                </form>
            </div>
            <StylePreview color1={formData.color1} color2={formData.color2} />
        </div>
    );
};

export default PreCheckoutApp;
