import React from 'react';
import '../styles/MultiSelectButton.css';

interface Props<T> {
    options: string[];
    selectedValue: T;
    values: T[];
    onChange: (value: T) => void;
    disabled?: boolean;
}

const MultiSelectButton = <T extends unknown>({
    options,
    selectedValue,
    values,
    onChange,
    disabled = false,
}: Props<T>): React.JSX.Element => {

    return (
        <div className="radio-button-container">
            {options.map((option, index) => (
                <button
                    key={option}
                    type="button"
                    className={`button radio-button ${selectedValue === values[index] ? 'selected' : ''}`}
                    onClick={() => onChange(values[index])}
                    disabled={disabled}
                >
                    {option}
                </button>
            ))}
        </div>
    );
}

export default MultiSelectButton;