import React from 'react';

import '../styles/Frontpage.css';
import '../styles/Success.css';
import background from '../assets/background.png'
import { track } from '../util/MixPanel';

interface Props {
    goBack: () => void;
}

const SuccessPage: React.FC<Props> = ({goBack}) => {
    track("Success page reached", {});
    return (
        <>
            <div className="background" style={{ backgroundImage:`url(${background})`}}></div>
            <div className="container">
                <div className="backgroundOverlay"></div>
                <div className="frontpage">
                    <div className="header">
                        <h1>OneTime</h1>
                        <p className="tagline">Fang øyeblikkene, ett bilde om gangen</p>
                        <div className='successBox'>
                            <h2 className='description'>Takk for din bestilling!</h2>
                            <p className='description'>Du vil snart motta en e-post med en lenke til ditt engangskamera.</p>
                            <p className='description'>Ikke fått noe mail? Vent noen minutter, og hvis du fortsatt ikke har fått noe, prøv å sjekke søppelpost-mappen</p>
                            <p className='description'>Hvis du har noen spørsmål, ikke nøl med å ta kontakt på <a className="betterA" href='mailto:support@onetime.no'>support@onetime.no</a></p>
                            <p className='description'>Vi håper du blir fornøyd med <span style={{fontFamily: "Courgette"}}>OneTime</span></p>
                        </div>
                    </div>
                    <div className="actions">
                        <button className="button orderButton" type='button' onClick={goBack}>Tilbake til forsiden</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SuccessPage;