import React from "react";

import "../styles/DisplayOnClickTextField.css";
import TextButton from "./TextButton";

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    className?: string;
    explanation?: string;
}

const DisplayOnClickTextField: React.FC<Props> = ({ label, value, onChange, placeholder, type, disabled, className, explanation }) => {

    const [display, setDisplay] = React.useState(false);

    const toggleDisplay = () => {
        setDisplay(!display);
    }

    return (
        <div className={`displayOnClickTextField ${className}`}>
            <div className="inputContainer">
                {!display && <TextButton onClick={toggleDisplay} >{label}</TextButton>}

                {display &&
                    <div className="form-group">
                        <label htmlFor="title">{placeholder}</label>
                        <input
                            type="text"
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        {explanation && <p className="">{explanation}</p>}
                    </div>
                }
            </div>
        </div>
    );
}

export default DisplayOnClickTextField;
